.loading {
  position: fixed;
  top: 50%;
  left: 50%;
  fill: rgb(0, 16, 247) !important;
  margin-left: -32px;
  margin-top: -32px;
  z-index: 99999;
}
.loading::before {
  content: "";
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  height: 100vh;
  background: rgba(245, 242, 242, 0.25);
  z-index: 1500;
  overflow: hidden;
}

.makeStyles-formControl-134 {
  margin: 0px 3px 30px 0 !important;
  position: relative;
  padding-bottom: 10px;
  vertical-align: unset;
}
